import PTZTokens from '@petz/ds-tokens/petz';
import { Component, Element, h, Host, Prop } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZBannerConfig } from './types/ptz-banner.enums';
import { PTZBannerTypes } from './types/ptz-banner.types';

@Component({
  tag: 'ptz-banner',
  styleUrl: 'ptz-banner.scss',
  shadow: false,
})
export class PTZBanner {
  /** Estilo de cor do Banner */
  @Prop() kind?: PTZBannerTypes.Kind = PTZBannerConfig.Kind.Repurchase;

  /** ativa o kind 'Focused' com delay antes do kind definido em prop */
  @Prop() hasKindDelayUpdate?: boolean = true;

  /** Ícone do Banner */
  @Prop() iconName: PTZIconTypes.Name;

  /** Variante do ícone no Banner */
  @Prop() iconVariant?: PTZIconTypes.Variant = 'line';

  /** Texto principal do Banner */
  @Prop() label: string;

  /** Texto de exibição em destaque com underline */
  @Prop() highlightedLabel: string;

  /** Valor da url do link */
  @Prop() href: string;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  /** @deprecated: Define se haverá hover no componente */
  @Prop() hover: boolean = true;

  @Element() el: HTMLElement;

  componentWillLoad() {
    console.warn('The component prop hover will be permanently be deprecated on the 3.0.0 release');
  }

  private get deprecatedClassNameIfNeeded() {
    if (this.hover) return 'ptz-banner-deprecated';

    return '';
  }

  render() {
    const KindPalette = {
      focused: {
        content: PTZTokens.colorNeutralWhite,
        hover: PTZTokens.colorNeutralWhite,
        bg: PTZTokens.colorPrimaryBrand,
      },
      repurchase: {
        content: PTZTokens.colorNeutralDarkerAccent,
        hover: PTZTokens.colorPrimaryBrand,
        bg: PTZTokens.colorNeutralLight,
      },
      signature: {
        content: PTZTokens.colorNeutralDarkerAccent,
        hover: PTZTokens.colorPrimaryBrand,
        bg: PTZTokens.colorNeutralLight,
      },
      combo: {
        content: PTZTokens.colorNeutralDarkerAccent,
        hover: PTZTokens.colorNeutralDarkerAccent,
        bg: PTZTokens.colorSupGreenLight,
      },
      offer: {
        content: PTZTokens.colorNeutralDarkerAccent,
        hover: PTZTokens.colorNeutralDarkerAccent,
        bg: PTZTokens.colorChartPurpleLight,
      },
      blackFriday: {
        content: PTZTokens.colorNeutralWhite,
        hover: PTZTokens.colorNeutralWhite,
        bg: PTZTokens.colorPrimaryBlackFriday,
      },
    };

    const initialColorState = () => {
      this.el.style.setProperty('--banner-color-content', KindPalette['focused'].content);
      this.el.style.setProperty('--banner-color-hover', KindPalette['focused'].hover);
      this.el.style.setProperty('--banner-color-bg', KindPalette['focused'].bg);
    };

    const updatedColorState = () => {
      this.el.style.setProperty('--banner-color-content', KindPalette[this.kind].content);
      this.el.style.setProperty('--banner-color-hover', KindPalette[this.kind].hover);
      this.el.style.setProperty('--banner-color-bg', KindPalette[this.kind].bg);
    };

    const handleBannerColor = () => {
      if (this.hasKindDelayUpdate) {
        initialColorState();
        setTimeout(updatedColorState, 2000);
      } else {
        updatedColorState();
      }
    };
    handleBannerColor();

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton height={58} rounded="none"></ptz-skeleton>
        ) : (
          <div data-testid={`${valueToLowerCaseForDataTestId(this.label)}-wrapper`} class={`banner-wrapper ${this.deprecatedClassNameIfNeeded}`}>
            <a class={`banner-container ${this.hover ? 'banner-container-with-hover' : ''}`} href={this.href}>
              <ptz-icon name={this.iconName} variant={this.iconVariant} size="lg" />
              <strong data-testid={`${valueToLowerCaseForDataTestId(this.label)}-label`} class="banner-label">{this.label}</strong>
              <span data-testid={`${valueToLowerCaseForDataTestId(this.label)}-highlighted-label`} class="banner-label-highlighted">{this.highlightedLabel}</span>
            </a>
          </div>
        )}
      </Host>
    );
  }
}
