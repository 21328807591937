import { EnumToArray } from '../../../utils/enumToArray';

enum Kind {
  Repurchase = 'repurchase',
  Signature = 'signature',
  Combo = 'combo',
  Offer = 'offer',
  Focused = 'focused',
  BlackFriday = 'blackFriday',
}

export const PTZBannerConfigList = {
  Kind: EnumToArray([Kind]),
};

export const PTZBannerConfig = {
  Kind,
};
