ptz-banner {
    --banner-color-bg: $petz-color-primary-brand;
    --banner-color-content: $petz-color-neutral-white;
    --banner-color-hover: $petz-color-neutral-white;

    .banner {
        &-wrapper {
            padding: 0;
            display: flex;
            background-color: var(--banner-color-bg);
            justify-content: center;
            width: 100%;
            transition: background-color 0.6s ease;
        }

        &-container {
            display: flex;
            align-items: center;
            padding: $petz-spacing-sm $petz-spacing-md;
            gap: $petz-spacing-2xs;
            box-sizing: border-box;
            border-bottom: $petz-border-md solid transparent;
            color: inherit;
            text-decoration: none;

            &.banner-container-with-hover:hover {
              border-bottom-color: var(--banner-color-hover);
              & * {
                  color: var(--banner-color-hover);
                  border-color: var(--banner-color-hover);
              }
          }
        }

        &-label {
            font-weight: $petz-font-weight-bold;
            color: var(--banner-color-content);
            transition: color 0.6s ease;

            &-highlighted {
                font-weight: $petz-font-weight-bold;
                font-size: $petz-font-size-3xs;
                color: var(--banner-color-content);
                border-bottom: $petz-border-sm solid var(--banner-color-content);
            }
        }
    }

  ptz-icon {
    display: flex;
    color: var(--banner-color-content);
    transition: color 0.6s ease;
  }

  ptz-skeleton {
    width: 100%;
  }
}
